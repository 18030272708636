<script>
import { get } from 'vuex-pathify'

export default {
	lang: 'books',
	data() {
		return { submitLoading: false }
	},

	computed: {
		productView() {
			return this.$parent.$parent
		},
		info() {
			return this.productView.product.info || {}
		},
		customizationKey() {
			return this.info.customizationKey
		},
		variantId() {
			return this.productView.selectedVariant.id
		},
		config() {
			return this.info.customizationData || {}
		},
		maxWidth() {
			return this.$vuetify.breakpoint.xs ? '80%' : this.$vuetify.breakpoint.sm ? '60%' : '100%'
		},
		urlCustomizationId: get('route@query.customizationId'),
		withoutCustomization() {
			return this.customizationKey === 'withoutCustomization'
		},
	},
	methods: {
		action() {
			if (this.$vuetify.breakpoint.mdAndUp) {
				this.$refs.bookEditor.openFirstTab()
			} else {
				this.$vuetify.goTo('.scroll-point', { offset: 60, duration: 1200 })
			}
		},
	},
}
</script>

<template>
	<div style="display: none" v-if="!!customizationKey">
		<portal to="Product.sku"><div></div></portal>
		<portal to="Product.stock"><div></div></portal>
		<portal to="Product.actions" v-if="!withoutCustomization">
			<Button @click="action" color="green" rounded x-large block :disabled="!!urlCustomizationId">
				{{ '¡Empecemos ya!' | lang }}
			</Button>
		</portal>
		<portal to="Product.info" :order="-1">
			<div class="lightyellow py-4" v-if="config.expansionPanels">
				<ExpansionPanels v-if="config.expansionPanels.length" :items="config.expansionPanels" small-tab />
			</div>
		</portal>
		<portal to="Product.info" :order="-10">
			<BookEditor
				ref="bookEditor"
				v-if="variantId && customizationKey"
				:variant-id="variantId"
				:product-id="productView.product.id"
				:customization-key="customizationKey"
				:config="config"
			/>
		</portal>
	</div>
</template>
