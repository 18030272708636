<script>
export default {
	lang: 'books',
	props: {
		uncatchedValidation: Object,
		config: Object,
		customizationRef: Object,
	},
	data() {
		return {
			customization: this.customizationRef,
		}
	},
	created() {
		this.$assignDeep(
			this.customization,
			{
				data: {
					name: null,
					name2: null,
					lang: null,
					birthdate: null,
				},
			},
			true
		)
	},
}
</script>

<template>
	<v-row class="flex-grow-1">
		<v-col cols="12" md="3">
			<TextField
				v-model="customization.data.name"
				:label="$lang('Nombre de MAMÁ')"
				class="customization-tf"
				:counter="config.nameMaxLen"
				:maxlength="config.nameMaxLen"
				dense
				plain
				solo
				height="50"
			/>
		</v-col>
		<v-col cols="12" md="3">
			<DatePickerDialog
				v-model="customization.data.birthdate"
				:label="$lang('Su fecha de nacimiento')"
				:placeholder="$lang('dd/mm/aaaa')"
				dateTextFormat="DD/MM/YYYY"
				textfield-class="customization-tf"
				:textfield-attrs="{ dense: true, plain: true, solo: true, height: 50 }"
			/>
		</v-col>
		<v-col cols="12" md="3">
			<TextField
				v-model="customization.data.name2"
				:label="$lang('De parte de')"
				class="customization-tf"
				:counter="config.name2MaxLen"
				:maxlength="config.name2MaxLen"
				dense
				plain
				solo
				height="50"
			/>
		</v-col>
		<v-col cols="12" md="3">
			<Select
				:label="$lang('Idioma')"
				:items="config.langs"
				v-model="customization.data.lang"
				class="customization-tf"
				dense
				plain
				solo
				height="50"
			/>
		</v-col>
	</v-row>
</template>
