<script>
export default {
	props: {
		product: Object,
		selectedVariantId: String,
		keyClass: String,
		valuesContainerClass: String,
		showSelectInput: {
			type: [Number, String, Boolean],
			default: 5,
		},
	},
	model: {
		prop: 'selectedVariantId',
		event: 'updateModel',
	},
	data() {
		return {
			groups: [],
		}
	},
	watch: {
		selectedVariantId() {
			this.onSelectedVariantIdChanged()
		},
	},
	computed: {
		variants() {
			return [...this.product.variants].sort((a, b) => a.position - b.position)
		},
		allKeys() {
			return this.product.vAttrsPos
		},
		mainVariantId() {
			return this.variants.find(({ main }) => main).id
		},
	},
	methods: {
		createGroups() {
			this.groups = []
			for (let key of this.allKeys) {
				let group = {}
				this.$assign(group, {
					key,
					values: [],
					selected: null,
					select: (value) => {
						if (group.selected == value) return
						group.selected = value
						this.onValueSelected()
					},
				})
				this.groups.push(group)
			}
			this.variants.forEach((variant) => {
				variant.attrs.forEach((attr) => {
					let group = this.groups.find((group) => group.key == attr.attrKey.k)
					if (!group.values.includes(attr.v)) group.values.push(attr.v)
				})
			})
		},
		onValueSelected() {
			let selectedValues = {}
			for (let group of this.groups) {
				if (!group.selected) return
				selectedValues[group.key] = group.selected
			}
			for (let variant of this.variants) {
				let found = true
				for (let attr of variant.attrs) {
					if (selectedValues[attr.attrKey.k] != attr.v) {
						found = false
						break
					}
				}
				if (found) return this.$emit('updateModel', variant.id)
			}
		},
		onSelectedVariantIdChanged() {
			if (!this.selectedVariantId) return this.$emit('updateModel', this.mainVariantId)
			let variant = this.variants.find(({ id }) => id == this.selectedVariantId)
			if (!variant) return this.$emit('updateModel', this.mainVariantId)
			for (let attr of variant.attrs) {
				let group = this.groups.find((group) => group.key == attr.attrKey.k)
				group.selected = attr.v
			}
		},
		shouldShowSelectInput(valuesLen) {
			if (typeof this.showSelectInput == 'boolean') return this.showSelectInput
			return valuesLen >= Number(this.showSelectInput)
		},
	},
	created() {
		//this.processVariants()
		if (this.product.hasUniqueVariant) return
		this.createGroups()
		this.onSelectedVariantIdChanged()
	},
}
</script>

<template>
	<div v-if="!product.hasUniqueVariant">
		<slot v-bind="{ groups }">
			<div v-for="group of groups" :key="group.key" class="pb-4">
				<slot name="key" v-bind="{ key: group.key, group, groups }">
					<div :class="keyClass || 'pb-1 font-weight-bold darkblue--text font-2'"
						v-if="(group.key || '').toLowerCase() == 'formatos'">
						Elige el formato de tu libro
					</div>
					<div :class="keyClass || 'pb-1 font-weight-bold text-uppercase darkblue--text font-2'" v-else>
						{{ group.key }}
					</div>
				</slot>
				<div :class="valuesContainerClass || 'd-flex align-center flex-wrap'">
					<slot name="values" v-bind="{ group, groups }">
						<template v-if="shouldShowSelectInput(group.values.length)">
							<Select :value="group.selected" :items="group.values" @input="group.select($event)" class="pt-0" />
						</template>
						<template v-else>
							<div v-for="(value, i) of group.values" :key="i">
								<slot name="valueButton" v-bind="{ value, group, groups }">
									<Button @click="group.select(value)" :key="value" color="primary" class="ma-1"
										:outlined="group.selected != value" small>
										{{ value }}
									</Button>
								</slot>
							</div>
						</template>
					</slot>
				</div>
			</div>
		</slot>
	</div>
</template>
