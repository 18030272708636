<script>
export default {
	lang: 'books',
	props: {
		uncatchedValidation: Object,
		config: Object,
		customizationRef: Object,
	},
	data() {
		return {
			customization: this.customizationRef,
		}
	},
	created() {
		this.$assignDeep(
			this.customization,
			{
				data: {
					name: null,
					age: null,
					gender: null,
					lang: null,
					name2multiple: false,
					name2: '[de parte de]',
				},
			},
			true
		)
	},
}
</script>

<template>
	<v-row class="flex-grow-1">
		<v-col cols="12" md="3" class="d-flex justify-center align-center">
			<TextField
				v-model="customization.data.name"
				:label="$lang('Nombre')"
				class="customization-tf"
				dense
				plain
				solo
				height="50"
				:counter="config.nameMaxLen"
				:maxlength="config.nameMaxLen"
			/>
		</v-col>
		<v-col cols="12" md="4" class="d-flex justify-center align-center">
			<TextField
				v-model="customization.data.age"
				:label="$lang('¿Cuántos años cumple?')"
				class="customization-tf"
				dense
				plain
				solo
				type="number"
				height="50"
			/>
		</v-col>
		<v-col cols="12" md="2" class="d-flex justify-center align-center">
			<Select
				:label="$lang('Idioma')"
				:items="config.langs"
				v-model="customization.data.lang"
				class="customization-tf"
				dense
				plain
				solo
				height="50"
			/>
		</v-col>
		<v-col cols="12" md="3" class="text-center d-flex flex-column align-center">
			<v-item-group
				v-model="customization.data.gender"
				:mandatory="!!customization.data.gender"
				class="nowrap"
			>
				<v-item v-slot="{ active, toggle }" value="f" class="mr-2">
					<Button
						@click="toggle"
						:color="`darkblue ${active ? 'darken-4' : ''}`"
						dark
						rounded
						class="medium-btn px-md-2 px-lg-4"
					>
						{{ 'Ella' | lang }}
					</Button>
				</v-item>
				<v-item v-slot="{ active, toggle }" value="m">
					<Button
						@click="toggle"
						:color="`darkblue ${active ? 'darken-4' : ''}`"
						dark
						rounded
						class="medium-btn px-md-2 px-lg-4"
					>
						{{ 'Él' | lang }}
					</Button>
				</v-item>
			</v-item-group>
			<div
				class="custom-error text-center mt-2 font-0"
				v-if="uncatchedValidation['customization.data.gender']"
			>
				{{ uncatchedValidation['customization.data.gender'] }}
			</div>
		</v-col>
		<!-- <v-col cols="12" md="6" lg="2" class="d-flex justify-center align-center">
			<div>
				<div class="white--text">
					{{ 'Cuantas personas regalan?' | lang }}
				</div>
				<v-radio-group v-model="customization.data.name2multiple" dark>
					<v-radio label="Una persona" :value="false"></v-radio>
					<v-radio label="Mas de una persona" :value="true"></v-radio>
				</v-radio-group>
			</div>
		</v-col> -->
	</v-row>
</template>

<style></style>
