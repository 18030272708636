<script>
import { Youtube, getIdFromUrl } from 'vue-youtube'
import kidsImg from './products-carousel-kids.png'
import vectorBg from './products-carousel-vector-bg.svg'

export default {
	lang: 'productInfo',
	components: {
		Youtube,
	},
	data() {
		return {
			youtubeVideoKey: 1,
			kidsImg,
			vectorBg,
		}
	},
	computed: {
		productView() {
			return this.$parent.$parent
		},
		product() {
			return this.productView.product
		},
		info() {
			return this.product.info || {}
		},
		showPortal() {
			return this.info?.templateKey == 'book-template'
		},
		data() {
			return this.info.templateData
		},
		xs() {
			return this.$vuetify.breakpoint.xs
		},
	},
	methods: {
		getYoutubeId(src) {
			return getIdFromUrl(src) || src
		},
		onVideoEnded() {
			this.$refs.youtube.player.stopVideo()
		},
	},
}
</script>

<template>
	<div style="display: none" v-if="showPortal">
		<portal to="Product.sku">
			<div class="d-flex">
				<v-icon small color="orange" v-for="i of 5" :key="i">mdi-star</v-icon>
			</div>
		</portal>
		<portal to="Product.below-name" v-if="data.description || info.description">
			<div class="pr-16 pb-6 preline">
				{{ data.description || info.description }}
			</div>
		</portal>
		<portal to="Product.info">
			<Media eager :src="xs ? data.topImgMobile : data.topImg" width="100%" />
			<div
				v-if="data.videoUrl"
				:style="{ background: data.videoBackgroundColor || 'white' }"
				class="video-container"
			>
				<Container :fluid="{ xs: true, md: false }" :max-width="{ md: '80%' }">
					<youtube
						:video-id="getYoutubeId(data.videoUrl)"
						fit-parent
						@ended="onVideoEnded"
						ref="youtube"
					/>
				</Container>
			</div>
			<Media
				eager
				:src="xs ? data.videoBottomImgMobile : data.videoBottomImg"
				width="100%"
				class="mb-8"
				:class="{ 'has-video': data.videoUrl }"
			/>
			<Container v-if="data.imgTextItems.length" :fluid="{ xs: true, sm: false }">
				<div
					v-for="(item, i) of data.imgTextItems"
					:key="i"
					class="d-flex align-center flex-column flex-sm-row pb-8"
				>
					<Media
						:src="item.image"
						:width="xs ? '100%' : '50%'"
						contain
						v-if="!item.inverted || xs"
						eager
					/>
					<div
						:style="{ width: xs ? '100%' : '50%' }"
						class="px-8 mt-5"
						:class="{ 'text-right': item.inverted && !xs, 'text-center': xs }"
					>
						<div class="blue--text font-6 font-weight-bold mb-6 preline">
							{{ item.title }}
						</div>
						<div class="preline">
							{{ item.text }}
						</div>
						<div class="py-12" v-if="item.ctaText">
							<Button
								color="orange"
								dark
								rounded
								class="px-16"
								x-large
								@click="$vuetify.goTo('.scroll-point', { offset: 60 })"
							>
								{{ item.ctaText }}
							</Button>
						</div>
					</div>
					<Media :src="item.image" width="50%" contain v-if="item.inverted && !xs" eager />
				</div>
			</Container>
			<Container :fluid="{ xs: true, md: false }" v-if="data.carouselItems.length">
				<Carousel
					:items="data.carouselItems"
					:options="{
						itemsPadding: 3,
						panelsPerView: { xs: 1.5, sm: 3.5, md: 5 },
						arrows: { md: true },
						arrowsClass: 'blue--text',
						arrowsMargin: 15,
					}"
					:flicking-options="{
						align: { xs: 'center', md: 'prev' },
						bound: true,
						moveType: { xs: 'freeScroll', md: 'snap' },
						circular: { xs: true, md: false },
					}"
				>
					<template #panel="{ item }">
						<div class="mb-8">
							<Media eager :src="item.image" :aspect-ratio="1" />
						</div>
						<div class="px-4 text-center preline">
							{{ item.text }}
						</div>
					</template>
				</Carousel>
			</Container>
			<div class="text-center py-12" v-if="data.ctaText">
				<Button
					color="orange"
					dark
					rounded
					class="px-16"
					x-large
					@click="$vuetify.goTo('.scroll-point', { offset: 60 })"
				>
					{{ data.ctaText }}
				</Button>
			</div>
			<Media eager :src="$vuetify.breakpoint.smAndDown ? data.endImgMobile : data.endImg" width="100%" />
		</portal>
		<portal to="Product.related-products">
			<div
				class="vector-bg py-16"
				:style="`background-image: url('${vectorBg}');`"
				v-if="!productView.pageLoading && productView.relatedProducts.length"
			>
				<Container>
					<div class="d-flex justify-start align-center">
						<div class="flex-shrink-1 kids-img pr-12" v-if="$vuetify.breakpoint.mdAndUp">
							<Media :src="kidsImg" :aspect-ratio="1.8125" contain :width="220" />
						</div>
						<h2
							class="font-6 font-sm-8 font-weight-bold darkblue--text text-center text-md-left pb-8 pb-md-0"
						>
							{{ 'Más series personalizables' | lang }}
						</h2>
					</div>
				</Container>
				<Container :fluid="{ xs: true, md: false }">
					<ProductsCarousel :products="productView.relatedProducts" list-id="related-products" />
				</Container>
			</div>
		</portal>
	</div>
</template>

<style scoped>
.video-container {
	position: relative;
	z-index: 1;
}
.video-container >>> iframe {
	box-shadow: 0px 4px 12px 6px rgb(0 0 0 / 50%) !important;
}
.has-video {
	position: relative;
	top: -7px;
}

.vector-bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
}
.kids-img {
	position: relative;
	top: 3px;
}
</style>
