var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":"","dark":"","fullscreen":"","transition":"fade-transition"}},[_c('div',{staticClass:"d-flex align-center justify-center overlay"},[_c('Button',{staticClass:"place-up",attrs:{"absolute":"","top":"","right":"","icon":"","x-large":"","dark":""},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-close")])],1),_c('Carousel',{staticStyle:{"height":"100%"},attrs:{"items":_vm.images,"options":{
				arrows: { md: true },
				arrowsMargin: 40,
				arrowsInset: true,
				viewportStyle: 'height: 100%',
				duration: 0,
			},"flicking-options":{ align: 'center', bound: true, threshold: 200 }},scopedSlots:_vm._u([{key:"panel",fn:function(ref){
			var image = ref.item;
			var active = ref.active;
return [_c('div',{staticClass:"d-flex justify-center align-center py-4 img",class:{ scale1: image.scale == 1, scale2: image.scale == 2, active: active },staticStyle:{"height":"100%"}},[_c('Media',{attrs:{"src":image.bigUrl,"height":"100%","max-width":"100%","aspect-ratio":"1","contain":""},on:{"click":function($event){return _vm.setImageScale(image)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white"}})],1)]},proxy:true}],null,true)})],1)]}}]),model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }