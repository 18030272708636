<script>
export default {
	computed: {
		productView() {
			return this.$parent.$parent
		},
		product() {
			return this.productView.product
		},
		info() {
			return this.product.info || {}
		},
		showPortal() {
			return this.info?.templateKey == 'adult-book-template'
		},
		data() {
			return this.info.templateData
		},
		xs() {
			return this.$vuetify.breakpoint.xs
		},
	},
}
</script>

<template>
	<div style="display: none" v-if="showPortal">
		<portal to="Product.sku">
			<div class="d-flex">
				<v-icon small color="orange" v-for="i of 5" :key="i">mdi-star</v-icon>
			</div>
		</portal>
		<portal to="Product.below-name" v-if="data.description || info.description">
			<div class="pr-16 pb-6 preline">
				{{ data.description || info.description }}
			</div>
		</portal>
		<portal to="Product.info">
			<Media eager :src="xs ? data.topImgMobile : data.topImg" width="100%" />
			<Media eager :src="xs ? data.largeImgMobile : data.largeImg" width="100%" />
			<Media
				:src="xs ? data.imageWithTextMobile : data.imageWithText"
				width="100%"
				class="py-15"
				:class="{ 'd-flex align-end': xs }"
			>
				<Container class="h100" v-if="!xs">
					<v-row justify="space-between" class="h100">
						<v-col
							cols="6"
							v-for="(item, index) of data.imageWithTextItems"
							:key="item.id"
							align-self="center"
							:class="{ 'd-flex flex-column align-end text-end': index % 2 !== 0 }"
						>
							<div class="font-weight-bold" style="color: #3a4d9b; max-width: 300px">
								{{ item.title }}
							</div>
							<div style="max-width: 300px">
								{{ item.text }}
							</div>
						</v-col>
					</v-row>
				</Container>
				<Carousel
					:items="data.imageWithTextItems"
					:options="{ autoplay: 4000, arrows: false, viewportClass: 'py-10' }"
					:flicking-options="{ align: 'center', moveType: ['strict', { count: 1 }] }"
					v-else
				>
					<template #panel="{ item }">
						<div
							class="d-flex flex-column align-center justify-center px-5 py-10"
							style="color: #3a4d9b"
						>
							<div class="font-weight-bold text-center">
								{{ item.title }}
							</div>
							<div class="black--text text-center">
								{{ item.text }}
							</div>
						</div>
					</template>
					<template #free-slot="{ currentIndex, moveTo }">
						<div class="index-container d-flex">
							<div
								v-for="i of data.imageWithTextItems.length"
								class="index rounded-circle"
								:class="{ active: currentIndex == i - 1 }"
								:key="i"
								@click="moveTo(i - 1)"
							></div>
						</div>
					</template>
				</Carousel>
			</Media>
			<Container fluid class="pa-0">
				<v-row no-gutters>
					<v-col
						cols="6"
						md="4"
						v-for="item of data.galleryImages"
						:key="item.id"
						style="overflow: hidden"
					>
						<Media
							:src="item.image"
							@click="$vuetify.goTo('.scroll-point', { offset: 60 })"
							class="img-hover"
						/>
					</v-col>
				</v-row>
			</Container>
			<Container v-if="data.imgTextItems" :fluid="{ xs: true, sm: false }" class="mt-10">
				<div
					v-for="(item, i) of data.imgTextItems"
					:key="i"
					class="d-flex align-center flex-column flex-sm-row pb-8"
				>
					<Media
						:src="item.image"
						:width="xs ? '100%' : '50%'"
						contain
						v-if="!item.inverted || xs"
						eager
					/>
					<div
						:style="{ width: xs ? '100%' : '50%' }"
						class="px-8"
						:class="{ 'text-end': item.inverted && !xs, 'text-center': xs }"
					>
						<div class="blue--text font-6 font-weight-bold mb-0 mb-md-6 preline">
							{{ item.title }}
						</div>
						<div class="preline">
							{{ item.text }}
						</div>
						<div class="py-12" v-if="item.ctaText">
							<Button
								color="orange"
								dark
								rounded
								class="px-16"
								x-large
								@click="$vuetify.goTo('.scroll-point', { offset: 60 })"
							>
								{{ item.ctaText }}
							</Button>
						</div>
					</div>
					<Media :src="item.image" width="50%" contain v-if="item.inverted && !xs" eager />
				</div>
			</Container>
			<Media eager :src="xs ? data.endImgMobile : data.endImg" width="100%" />
		</portal>

		<portal to="Product.related-products">
			<div
				v-if="!productView.pageLoading && productView.relatedProducts.length"
				class="vector-bg py-16"
				:style="{
					'background-image': data.relatedProductsBg ? `url('${data.relatedProductsBg}')` : null,
				}"
			>
				<Container
					class="d-flex align-center justify-center justify-md-start font-7 font-weight-bold darkblue--text"
				>
					<Media
						:src="data.relatedProductsTitleImg"
						v-if="data.relatedProductsTitleImg"
						max-width="300px"
						contain
						class="translate d-none d-md-block"
					/>
					<span class="pb-8">
						{{ data.relatedProductsTitle }}
					</span>
				</Container>
				<Container :fluid="{ xs: true, md: false }">
					<ProductsCarousel :products="productView.relatedProducts" list-id="related-products" />
				</Container>
			</div>
		</portal>

		<portal to="Product.under">
			<template v-if="!productView.pageLoading">
				<Home-F-MoreInfo :img="data.MoreInfoImg" />
				<Home-H-Reviews :images="data.reviewsImages" adult />
				<Home-I-Newsletter :img="data.NewsletterImg" :imgMobile="data.NewsletterImgMobile" />
			</template>
		</portal>
	</div>
</template>

<style scoped>
.vector-bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
}
.index-container {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 4;
}

.index {
	width: 12px;
	height: 12px;
	margin: 0 5px;
	transition: all 0.3s;
	cursor: pointer;
	background-color: transparent;
	border: 2px solid #3a4d9b;
}

.index.active {
	background-color: #3a4d9b;
}
.img-hover:hover {
	cursor: pointer;
	transform: scale(1.1);
	transition: 0.2s ease-in-out;
}
.translate {
	transform: translateY(6rem);
}
</style>
