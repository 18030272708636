var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"flex-grow-1"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('TextField',{staticClass:"customization-tf",attrs:{"label":_vm.$lang('Nombre de tu peque'),"dense":"","plain":"","solo":"","height":"50","counter":_vm.config.nameMaxLen,"maxlength":_vm.config.nameMaxLen},model:{value:(_vm.customization.data.name),callback:function ($$v) {_vm.$set(_vm.customization.data, "name", $$v)},expression:"customization.data.name"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3","align-self":"center"}},[_c('v-item-group',{staticClass:"nowrap",attrs:{"mandatory":!!_vm.customization.data.gender},model:{value:(_vm.customization.data.gender),callback:function ($$v) {_vm.$set(_vm.customization.data, "gender", $$v)},expression:"customization.data.gender"}},[_c('v-item',{staticClass:"mr-2",attrs:{"value":"f"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('Button',{staticClass:"medium-btn px-md-2 px-lg-4",attrs:{"color":("darkblue " + (active ? 'darken-4' : '')),"dark":"","rounded":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm._f("lang")('Ella'))+" ")])]}}])}),_c('v-item',{attrs:{"value":"m"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('Button',{staticClass:"medium-btn px-md-2 px-lg-4",attrs:{"color":("darkblue " + (active ? 'darken-4' : '')),"dark":"","rounded":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm._f("lang")('Él'))+" ")])]}}])})],1),(_vm.uncatchedValidation['customization.data.gender'])?_c('div',{staticClass:"custom-error text-center mt-2 font-0"},[_vm._v(" "+_vm._s(_vm.uncatchedValidation['customization.data.gender'])+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('Select',{staticClass:"customization-tf",attrs:{"label":_vm.$lang('Color de la historia'),"items":_vm.config.colors,"item-value":"colorId","dense":"","plain":"","solo":"","height":"50"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"circle-color mr-4",style:({ background: item.hex })}),_c('span',[_vm._v(_vm._s(item.text))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"circle-color mr-4",style:({ background: item.hex })}),_c('span',[_vm._v(_vm._s(item.text))])])]}}]),model:{value:(_vm.customization.data.color),callback:function ($$v) {_vm.$set(_vm.customization.data, "color", $$v)},expression:"customization.data.color"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('Select',{staticClass:"customization-tf",attrs:{"label":_vm.$lang('Idioma'),"items":_vm.config.langs,"dense":"","plain":"","solo":"","height":"50"},model:{value:(_vm.customization.data.lang),callback:function ($$v) {_vm.$set(_vm.customization.data, "lang", $$v)},expression:"customization.data.lang"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }