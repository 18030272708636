<script>
export default {
	computed: {
		product() {
			return this.$parent.$parent.product
		},
		description() {
			return this.product.info?.templateData?.description || this.product.info?.description
		},
		showPortals() {
			return this.product.info?.templateKey == 'description'
		},
	},
}
</script>

<template>
	<div style="display:none" v-if="showPortals">
		<portal to="Product.below-name" v-if="description">
			<div class="pr-16 pb-6 preline font-1">
				{{ description }}
			</div>
		</portal>
	</div>
</template>

<style scoped></style>
