<script>
import { get } from 'vuex-pathify'

export default {
	lang: 'shop',
	metaInfo: {
		title: 'Upsells',
	},
	data() {
		return {
			upsells: null,
			loading: false,
		}
	},
	computed: {
		productId: get('route@params.id'),
		listId() {
			return 'upsells'
		},
	},
	methods: {
		async addSelectedProductsToCart() {
			this.loading = true
			for (let [index, product] of this.upsells.entries()) {
				if (product.selected) {
					this.$eventer.trigger('product:click', {
						product,
						listId: this.listId,
						index,
					})
					await this.$shop.addToCart({
						productId: product.id,
						variantId: product.variants.find((variant) => variant.main).id,
						qty: 1,
						sum: false,
						showUpsells: false,
						loading: false,
						showCart: false,
					})
				}
			}

			await this.$shop.goShop()
			this.$shop.showCart()
		},
	},
	async mounted() {
		let upsells = this.$store.get('shop/upsells') || null
		this.$store.set('shop/upsells', null)
		if (!upsells?.length) {
			upsells = await this.$shop.getUpsells({ productId: this.productId })
		}
		if (!upsells?.length) {
			await this.$router.replace(this.$shop.getShopRoute())
			this.$shop.showCart()
			return
		}
		this.upsells = upsells
		for (let product of this.upsells) {
			this.$assign(product, { selected: false })
		}
		this.$eventer.trigger('page:view')
		this.$eventer.trigger('product-list:view', {
			products: this.upsells,
			listId: this.listId,
		})
	},
}
</script>
<template>
	<div class="page-bg-container">
		<div class="d-flex align-center justify-center py-16" v-if="!upsells">
			<v-progress-circular indeterminate size="60" color="darkblue" />
		</div>
		<Container class="d-flex flex-column align-center pb-8" v-else>
			<Container
				class="font-6 font-md-10 font-weight-bold text-center darkblue--text"
				:width="{ md: 600 }"
				:fluid="{ xs: true, md: false }"
			>
				{{ '¡Haz que su sorpresa sea aún más espectacular!' | lang }}
			</Container>
			<div class="font-2 font-md-2 text-center">
				{{ 'Selecciona los productos que te gusten y completa tu regalo.' | lang }}
			</div>
			<div class="cards-container mt-5 mb-8">
				<UpsellProductCard
					v-for="(product, i) of upsells"
					v-model="product.selected"
					:product="product"
					:key="i"
				/>
			</div>
			<Button
				color="green"
				width="225px"
				height="50px"
				x-large
				@click="addSelectedProductsToCart()"
				:loading="loading"
			>
				{{ 'Continuar' | lang }}
			</Button>
		</Container>
	</div>
</template>
<style scoped>
.cards-container {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(230px, 280px));
	gap: 30px;
	justify-content: center;
}
</style>
