<script>
export default {
	lang: 'books',
	props: {
		uncatchedValidation: Object,
		config: Object,
		customizationRef: Object,
	},
	data() {
		return {
			customization: this.customizationRef,
		}
	},
	created() {
		this.$assignDeep(
			this.customization,
			{
				data: {
					name: null,
					gender: null,
				},
			},
			true
		)
	},
}
</script>

<template>
	<v-row class="flex-grow-1">
		<v-col cols="12" md="8">
			<TextField
				v-model="customization.data.name"
				:label="$lang('Nombre')"
				class="customization-tf"
				dense
				plain
				solo
				height="50"
				:counter="config.nameMaxLen"
				:maxlength="config.nameMaxLen"
			/>
		</v-col>
		<v-col cols="12" md="4">
			<Select
				:label="$lang('Idioma')"
				:items="config.langs"
				v-model="customization.data.lang"
				class="customization-tf"
				dense
				plain
				solo
				height="50"
			/>
		</v-col>
	</v-row>
</template>
