import { render, staticRenderFns } from "./unused--comp_ProductPageCustomizationInputs-colors.vue?vue&type=template&id=ba295f10&"
import script from "./unused--comp_ProductPageCustomizationInputs-colors.vue?vue&type=script&lang=js&"
export * from "./unused--comp_ProductPageCustomizationInputs-colors.vue?vue&type=script&lang=js&"
import style0 from "./unused--comp_ProductPageCustomizationInputs-colors.vue?vue&type=style&index=0&id=ba295f10&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VItem,VItemGroup,VRow})
