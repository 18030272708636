<script>
export default {
	lang: 'books',
	props: {
		uncatchedValidation: Object,
		config: Object,
		customizationRef: Object,
	},
	data() {
		return {
			customization: this.customizationRef,
		}
	},
	created() {
		this.$assignDeep(
			this.customization,
			{
				data: {
					name: null,
					gender: null,
					lang: null,
				},
			},
			true
		)
	},
}
</script>

<template>
	<v-row class="flex-grow-1">
		<v-col cols="12" md="5">
			<TextField
				v-model="customization.data.name"
				:label="$lang('Nombre')"
				class="customization-tf"
				dense
				plain
				solo
				height="50"
				:counter="config.nameMaxLen"
				:maxlength="config.nameMaxLen"
			/>
		</v-col>
		<v-col cols="12" md="4">
			<Select
				:label="$lang('Idioma')"
				:items="config.langs"
				v-model="customization.data.lang"
				class="customization-tf"
				dense
				plain
				solo
				height="50"
			/>
		</v-col>
		<v-col cols="12" md="3" align-self="center" class="text-center">
			<v-item-group
				v-model="customization.data.gender"
				:mandatory="!!customization.data.gender"
				class="nowrap"
			>
				<v-item v-slot="{ active, toggle }" value="f" class="mr-2">
					<Button
						@click="toggle"
						:color="`darkblue ${active ? 'darken-4' : ''}`"
						dark
						rounded
						class="medium-btn px-md-2 px-lg-4"
					>
						{{ 'Ella' | lang }}
					</Button>
				</v-item>
				<v-item v-slot="{ active, toggle }" value="m">
					<Button
						@click="toggle"
						:color="`darkblue ${active ? 'darken-4' : ''}`"
						dark
						rounded
						class="medium-btn px-md-2 px-lg-4"
					>
						{{ 'Él' | lang }}
					</Button>
				</v-item>
			</v-item-group>
			<div
				class="custom-error text-center mt-2 font-0"
				v-if="uncatchedValidation['customization.data.gender']"
			>
				{{ uncatchedValidation['customization.data.gender'] }}
			</div>
		</v-col>
	</v-row>
</template>
